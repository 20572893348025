@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&family=Lobster&display=swap");

html,
body {
  background-color: #e6ecf7;
  font-size: 20px;
  padding-top: 5px;
}

.container {
  width: min(700px, 100vw);
  text-align: center;
  margin: 0 auto 0;
}

.title {
  font-family: "Lobster", cursive;
  font-size: 60px;
  margin-bottom: 20px;
}

textarea {
  resize: none;
  width: min(500px, 80%);
  height: 50px;
  padding: 5px;
  font-size: 28px;
  box-sizing: border-box;
}

.music-card {
  width: 100%;
  font-size: 18px;
  background-color: #fff;
  border-top: 2px solid #ccc;
  box-sizing: border-box;
  padding: 10px 0 10px;
}

.music-card-open {
  font-weight: 600;
  background-color: rgb(221, 229, 255);
}

.music-card-title {
  width: 40%;
  display: inline-block;
}

.music-card-artist {
  width: 40%;
  display: inline-block;
}

.music-card-book {
  width: 10%;
  display: inline-block;
}

.music-card-page {
  width: 10%;
  display: inline-block;
}

.login-button {
  padding: 10px;
  background-color: #4885f0;
  width: 40%;
  margin: 0 auto 0;
  color: white;
}

.login-button:hover {
  background-color: #2d60ba;
}

.login-window {
  position: fixed;
  width: 70%;
  height: min(300px, 80%);
  left: 15%;
  top: calc(50% - min(300%, 80%) / 2);
  background-color: #ddd;
  border: 2px solid black;
}

.login-header {
  font-size: 24px;
}

.pass-enter {
  background-color: #4885f0;
  padding: 10px;
  width: 40%;
  margin: 0 auto 0;
  margin-top: 20px;
  font-size: 22px;
  color: #fff;
}

.pass-enter:hover {
  background-color: #2d60ba;
}

#login1 {
  display: none;
}

#pass-attempt-success {
  color: green;
  display: none;
}

#pass-attempt-fail {
  color: red;
  display: none;
}

.pass-attempt-message {
  margin-top: 10px;
  font-weight: 700;
}

.add-item-textarea {
  font-size: 18px;
  text-align: center;
  padding: 0;
  height: 50px;
  width: 100%;
}

.add-item-button {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  background-color: #4885f0;
  color: #fff;
  font-weight: 700;
}

.add-item-button:hover {
  background-color: #2d60ba;
}

.edit-item-button {
  display: inline-block;
  width: 50%;
  padding: 10px;
  background-color: #4885f0;
  color: #fff;
  font-weight: 700;
  box-sizing: border-box;
}

.edit-item-button:hover {
  background-color: #2d60ba;
}

.delete-item-button {
  box-sizing: border-box;
  display: inline-block;
  width: 50%;
  padding: 10px;
  background-color: #f83d2f;
  color: #fff;
  font-weight: 700;
}

.delete-item-button:hover {
  background-color: #be1111;
}
